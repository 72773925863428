<template>
  <div class="container-fluid">
    <div class="row bg-login-submit">
      <a class="btn login-back btn--dark" href="https://www.mylastwill.co.uk">
        <i class="fa fa-chevron-left"></i>
      </a>
      <div class="whole404">
        <section class="main">
          <div class="four left">4</div>
          <div class="search">
            <p>page not found</p>
          </div>
          <div class="four right">4</div>
        </section>
        <section class="steps">
          <div class="step-left step-left-1"></div>
          <div class="step-right step-right-1"></div>
          <div class="step-left step-left-2"></div>
          <div class="step-right step-right-2"></div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginRegister',
  components: {},
  created() {},
  watch: {},
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
